var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "progress-ring" }, [
    _c("svg", { attrs: { height: _vm.radius * 2, width: _vm.radius * 2 } }, [
      _c("circle"),
      _vm._v(" "),
      _c("circle", {
        style: { strokeDashoffset: _vm.strokeDashoffset },
        attrs: {
          stroke: "white",
          fill: "transparent",
          "stroke-linecap": "round",
          "stroke-dasharray": _vm.circumference + " " + _vm.circumference,
          "stroke-width": _vm.stroke,
          r: _vm.normalizedRadius,
          cx: _vm.radius,
          cy: _vm.radius
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content content-center" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }